import React, { useState } from "react";
import NavbarAdmin from "../../Components/NavbarAdmin";
// import "./excel.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import MUIDataTable from "mui-datatables";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import url from "../../baseUrl";
import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst";
import DeleteIcon from "@mui/icons-material/Delete"; // Import icon delete
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import Directory from "../../baseDirectory";
import "./excel.css";
function ImportExcel() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null); // State untuk menyimpan file

  const MySwal = withReactContent(Swal);
  const { eventId } = useParams();
  const tokenJwt = useSelector((state) => state.admTokenReducer.data.token);
  const dispatch = useDispatch();

  const expire = jwt_decode(tokenJwt).exp;

  const axiosJWT = axios.create();
  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${url}/accessToken`);
        config.headers.Authorization = response.data.adm_access_token;
        dispatch({
          type: GET_TOKEN,
          payload: {
            token: response.data.adm_access_token,
          },
        });
      }
      return config;
    },
    (error) => {
      axiosJWT.get(`${url}/logout`).then(() => {});
      localStorage.removeItem("LoginSdm");
      dispatch({ type: ADMIN_LOGOUT });
      return Promise.reject(error);
    }
  );

  const expectedHeaders = [
    "nisn",
    "nama",
    "tanggal_lahir",
    "no_hp",
    "email",
    "kelas",
    "jenjang_pendidikan",
  ];

  const handleFileUpload = (e) => {
    e.preventDefault(); // Prevent default behavior (stop file from being opened)

    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (!files || files.length === 0) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Tidak ada file yang dipilih \n okok",
      });
      return;
    }
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      let arrayBuffer;
      if (event.target) {
        arrayBuffer = event.target.result;
      }
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const fileHeaders = Object.keys(jsonData[0]);
      const isValid = expectedHeaders.every((header) =>
        fileHeaders.includes(header)
      );

      if (!isValid) {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Format file tidak sesuai!",
        });
        return;
      }

      setData(jsonData);
      setUploadedFile(file); // Simpan file yang diunggah
    };

    reader.readAsArrayBuffer(file);
    MySwal.fire({
      position: "top-center",
      icon: "success",
      title: "Berhasil Mengupload File Excel",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    setData([]); // Reset data jika file dihapus
    MySwal.fire({
      position: "top-center",
      icon: "info",
      title: "File dihapus",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleDownloadFormat = () => {
    const exportData = [
      {
        nisn: "1234567890",
        nama: "rico kurnia pujiantoro",
        tanggal_lahir: "12/06/1999",
        no_hp: "081234567890",
        email: "ricoworker@gmail.com",
        kelas: "12",
        jenjang_pendidikan: "SMA",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Format Import");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, "format_import.xlsx");
  };

  // MUI Datatables
  const options = {
    selectableRows: "none", // Disable the checkboxes
  };

  const columns = [
    "nisn",
    "nama",
    "tanggal_lahir",
    "no_hp",
    "email",
    "kelas",
    "jenjang_pendidikan",
  ];
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingUpload(true);

    if (!uploadedFile) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        html: "Tidak ada file yang diunggah", // Use <br/> for line break
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);

    // Log FormData content
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    try {
      const response = await axiosJWT.post(
        `${url}/peserta2/${eventId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${tokenJwt}`,
          },
        }
      );

      MySwal.fire({
        position: "top-center",
        icon: "success",
        title: "File berhasil dikirim ",
        html: `${response.data.message}<br/>Berhasil: ${response.data.submitted.length}<br/>Gagal: ${response.data.notSubmitted.length}`,
        showConfirmButton: false,
        // timer: 1500,
      });
      setLoadingUpload(false);
      navigate(`/${Directory}/detail_event/${eventId}`);
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      setLoadingUpload(false);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Terjadi kesalahan saat mengirim file \n okok",
      });
      console.error("File upload failed:", error);
    }
  };

  return (
    <>
      <NavbarAdmin />
      <div className="container container-fluid">
        <div className="card-importExcel d-flex justify-content-center flex-column">
          <div
            className="mt-3"
            onDrop={handleFileUpload}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById("input-xlsx").click()}
            id="drop-area"
          >
            {uploadedFile ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>{uploadedFile.name}</span>
                <DeleteIcon
                  style={{
                    zIndex: "30",
                    cursor: "pointer",
                    marginLeft: "10px",
                    color: "red",
                  }}
                  onClick={handleDeleteFile}
                />
              </div>
            ) : (
              "Drag and drop or select an Excel file here"
            )}
          </div>
          <input
            disabled={uploadedFile ? true : false}
            onChange={handleFileUpload}
            type="file"
            id="input-xlsx"
            className="d-none"
          />
          <div className="d-flex justify-content-between ">
            <button
              onClick={handleDownloadFormat}
              type="button"
              className="btn btn-success"
            >
              Download Format Import
              <SaveAltIcon className="my-auto ms-2 mb-1" />
            </button>
            <button
              disabled={loadingUpload}
              onClick={handleSubmit}
              type="button"
              className="btn btn-danger"
            >
              {loadingUpload ? "Loading..." : "Tambahkan"}
              <DownloadDoneIcon className="my-auto ms-2 mb-1" />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-3 px-3">
        <MUIDataTable
          title={"Privew Data Excel"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}

export default ImportExcel;
