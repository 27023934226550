import React from "react";
import Directory from "../baseDirectory";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ListKaryawan from "../Pages/admin/ListKaryawan";
import HasilMbti from "../Pages/user/HasilMbti";
import Loginadmin from "../Pages/LoginAdmin";
import ListEvent from "../Pages/admin/ListEvent";
import FormEvent from "../Pages/admin/FormEvent";
import DetailEvent from "../Pages/admin/Detail_event";
import EditPeserta from "../Pages/admin/EditPeserta";
import ImportExcel from "../Pages/admin/ImportExcel";
const AdminRoute = () => {
  const auth = useSelector((state) => state.auth);

  const { isLogged } = auth;

  return (
    <Routes>
      <Route
        path={`/login_admin`}
        element={
          isLogged ? (
            <Navigate to={`/${Directory}/list_event`} />
          ) : (
            <Loginadmin />
          )
        }
      />

      <Route
        path={`/list_karyawan`}
        element={
          isLogged ? (
            <ListKaryawan />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
      <Route
        path={`/detail_event/:eventId`}
        element={
          isLogged ? (
            <DetailEvent />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
      <Route
        path={`/list_event`}
        element={
          isLogged ? (
            <ListEvent />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
      <Route
        path={`/form_event`}
        element={
          isLogged ? (
            <FormEvent />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
      <Route
        path={`/add_peserta/:eventId`}
        element={
          isLogged ? (
            <ImportExcel />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
      <Route
        path={`/import_excel/:eventId`}
        element={
          isLogged ? (
            <ImportExcel />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
      <Route
        path={`/edit_peserta/:pesertaId/:eventId`}
        element={
          isLogged ? (
            <EditPeserta />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
      <Route
        path={`/hasil_mbti`}
        element={
          isLogged ? (
            <HasilMbti />
          ) : (
            <Navigate to={`/${Directory}/login_admin`} />
          )
        }
      />
    </Routes>
  );
};

export default AdminRoute;
