import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Directory from "../../baseDirectory";
import { useNavigate } from "react-router-dom";
import Logogo2 from "../../Assets/logogo2.png";
const CheckResult = () => {
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Cek apakah permintaan berhasil berdasarkan payload
    try {
      navigate(`/${Directory}/result`, { state: { data } });
    } catch (err) {
      console.log(err);

      setError(err.response.data.msg);
    }
  };
  const handleInputChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/\s+/g, "");
    setValue("kodeEvent", sanitizedInput, { shouldValidate: true });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container d-flex justify-content-center min-vh-100 align-items-center">
          <div className="row g-3 col-md-6 card p-5">
            <div className="d-flex justify-content-center">
              <img src={Logogo2} height={"130px"} alt="Ganesha Operation" />
            </div>
            <div className="d-flex justify-content-center">
              <h3>Cek Hasil Mbti Ganesha Operation</h3>
            </div>
            <label className="fw-bold">Masukan NISN</label>
            <input
              name="NISN"
              type="number"
              className="form-control"
              id="inputNISN"
              placeholder="Masukan NISN Peserta"
              style={{ marginBottom: 10 }}
              {...register("nisn", {
                required: true,
                // minLength: 16,
              })}
            />
            {errors.nisn?.type === "required" && (
              <span role="alert" style={{ color: "red" }}>
                Masukan nisn
              </span>
            )}
            <label className="fw-bold">Masukan Kode Event</label>
            <input
              onChange={handleInputChange}
              onKeyUp={handleInputChange}
              name="Kode Event"
              type="text"
              className="form-control"
              id="inputKode"
              placeholder="Masukan kode event"
              {...register("kodeEvent", {
                required: true,
              })}
            />
            {errors.kodeEvent?.type === "required" && (
              <span role="alert" style={{ color: "red" }}>
                Masukan kode event
              </span>
            )}

            {error && (
              <p role="alert" style={{ color: "red" }}>
                {error}
              </p>
            )}

            <button className="btn btn-danger" type="submit">
              Cek Hasil
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CheckResult;
