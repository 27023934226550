import "./FinishSubmitTest.css";
import Peoplestar from "../Assets/Peoples.png";
import { Navbar } from "../Components/Navbar";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const FinishSubmitTest = () => {
  const { JenisTes } = useParams();
  const location = useLocation();
  const { isPublish } = location.state || {}; // Access the passed state
  console.log(isPublish);

  return (
    <>
      <Navbar />
      <div className="finishsubmittest">
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="bgg">
            <img className="peoplesubmit" src={Peoplestar} alt="people" />
          </div>
        </div>

        <div className="Jawabantersimpan">
          <h2>Jawaban Tersimpan</h2>

          <i className="bi bi-check-circle-fill" style={{ fontSize: "450%" }} />
          <div className="thanks-finishsubmit">
            <h2>Terima kasih</h2>
            <h6>Anda telah menyelesaikan tes {JenisTes}</h6>
            <h6>Ganesha Operation</h6>
          </div>
          {isPublish && (
            <p>
              {" "}
              <Link
                className="me-2 text-warning fw-bold"
                to={`/mbtiSiswa/check`}
              >
                Klik Disini
              </Link>
              Untuk Melihat Hasil MBTI Kamu
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default FinishSubmitTest;
